.wrapper-search {
    position: absolute;
    top: 57vh;
    width: 100%;
    z-index: 200;
}

.header-immobili ~ .wrapper-search {
    top: 22vh !important;
}

.search-bar-container {
    background-color: var(--filter-bg);
    padding: 1rem;
    border-radius: 15px;
    margin: 0 auto;
    max-width: 900px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    align-items: center;
    box-shadow: var(--shadow-black);
}

.search-bar-item {
    display: flex;
    align-items: center;
}

.search-bar-item label {
    background-color: var(--filter-label-bg);
    color: var(--filter-label-color);
    border-radius: 5px 0 0 5px;
    padding: 0.5rem 1rem;
    margin: 0;
    min-width: 33%;
}

.search-bar-item select {
    background-color: var(--filter-select-bg);
    color: var(--filter-select-color);
    border: none;
    border-radius: 0 5px 5px 0;
    padding: 0.5rem 1rem !important;
    min-width: 67%;
}

.search-bar-container button {
    background-color: var(--filter-button-bg);
    color: var(--filter-button-color);
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
}

.search-bar-container button:hover {
    background-color: var(--filter-button-bg-hover);
    color: var(--filter-button-color-hover);
}

.search-bar-container select:focus,
.search-bar-container button:focus {
    outline: none;
    box-shadow: none;
}

.range-bg{
    background-color: var(--filter-range-bg);
    border-radius: var(--rounded-card);
}

.range-bg input{
    margin: 0rem 0.5rem;
}

.range-bg span{
    margin-right: 0.5rem;
}

select {
    appearance: none; /* Rimuove la freccia di default in alcuni browser */
    -webkit-appearance: none; /* Rimuove la freccia di default su Safari */
    -moz-appearance: none; /* Rimuove la freccia di default su Firefox */
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 192'%3E%3Cpath fill='%23000' d='M48 72l48 48 48-48z'/%3E%3C/svg%3E") no-repeat center;
    background-position: calc(100% - 1rem) center;
    background-size: 1.3rem;
    padding-right: 1rem; /* Spazio per la freccia */
}

@media (max-width: 767px) {
    .search-bar-container {
        max-width: 95vw;
        margin: 0.5rem auto;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .search-bar-container {
        max-width: 95vw;
    }
}