.servizi-section {
    width: 65vw;
    color: var(--servizi-color);
    position: relative;
    top: -50px;
}

.servizi-section-bg-container {
    width: 100vw;
    position: fixed;
    top: 0;
    min-height: 100vh;
    z-index: 0;
}

.servizi-section-bg {
    width: 65vw;
    background-color: var(--servizi-bg);
    box-shadow: var(--shadow-light-black);
    min-height: 100vh;
}

.servizi-section-item.left {
    display: grid !important;
    grid-template-columns: 1fr 2fr;
}

.servizi-section-item.right {
    display: grid !important;
    grid-template-columns: 2fr 1fr;
}

.servizi-section-item-img, .servizi-section-item-text {
    padding: 2.5rem;
    margin: 1.5rem;
}

.servizi-section-item-img > img {
    max-width: 100%;
    border-radius: var(--rounded-card);
    box-shadow: var(--shadow-black);
}

.servizi-section-item-title {
    color: var(--servizi-section-title);
}

.servizi-section-item-p{
    text-align: justify;
}

/*MEDIA SCREEN*/
@media only screen and (max-width: 1200px){
    .servizi-section {
        width: 80vw;
    }
    .servizi-section-bg{
        width: 80vw;
    }
    .servizi-section-item.left {
        grid-template-columns: 33vw 1fr;
    }
    .servizi-section-item.right {
        grid-template-columns: 1fr 33vw;
    }
}
@media only screen and (max-width: 850px){
    .servizi-section-item {
        grid-template-columns: 1fr !important;
    }
}

@media only screen and (max-width: 600px){
}