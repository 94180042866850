.button-container{
    text-align: center;
}

.all-research-button{
    background-color: var(--filter-label-bg);
    color: var(--filter-label-color);
    border-radius: 0.8rem;
    border: 0px;
    padding: 1rem 4rem;
    margin: auto;
    min-width: 33%;
    box-shadow: var(--color-card-shadow) !important;
}

.all-research-button:hover{
    animation: button-hover 0.10s ease-in forwards;
    background-color: var(--filter-label-bg);
    color: var(--filter-label-color);
}

.no-ads{
    width: 100vw;
    text-align: center;
    margin: 3rem auto;
}

.home-wrapper .card-container{
    margin: 4rem auto !important
}

@keyframes button-hover {
    to{
        transform: translateY(-10px) scale(1.035)
    }
}