footer {
    background-color: var(--white);
    color: var(--footer-color);
    z-index: 5;
    position: relative;
    text-align: center;
}
footer > section {
    background-color: var(--footer-bg);
    width: 100%;
    height: 100%;
    margin: auto !important;
}
.footer-end{
    margin: 0 !important;
    padding: 0.5rem !important;
    color: var(--footer-color);
    text-align: center;
}
.footer-end > a{
    color: var(--footer-color);
}
footer .nav-link{
    font-size: 1.0rem !important;
    text-decoration: none;
    color: var(--footer-item-color);
}
img.logo {
    max-width: 150px; 
    max-height: 150px !important;
    margin: auto !important;
}