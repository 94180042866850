.navbar-brand {
    padding: 0 !important;
}

.navbar-brand img {
    height: 65px;
}
.navbar {
    background-color: var(--nav-background);
    position: fixed !important;
    top: 0px;
    width: 100vw;
    z-index: 1000;
    box-shadow: var(--shadow-black);
}
.navbar-nav .nav-link {
    color: var(--nav-item-color) !important;
    font-size: 1.3rem;
    text-align: center;
}
.nav-item:hover .nav-link {
    color: var(--nav-item-color-hover) !important;
}
.nav-item {
    background-color: var(--nav-item-background);
}
.nav-item:hover {
    background-color: var(--nav-item-background-hover);
}