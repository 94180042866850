:root {
    --rounded-card: 0.8rem;
    --color-card-shadow: #0d0d0d55;
}

.card{
    border: none !important;
    box-shadow: 15px 15px 30px var(--color-card-shadow) !important;
    border-radius: var(--rounded-card) !important;
}

.card-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(20vw, 32vw));
    gap: 25px;
    max-width: 75vw;
    margin: auto;
    min-height: 60vh;
    min-height: 60svh;
}

.card-animation-enter{
    transform: translateY(200px);
}

.card-item {
    height: 45vh !important;
}

.card-item:hover{
    animation: card-hover 0.10s ease-in forwards;
}

.card-item--container-img {
    position: relative;
    height: 50% !important;
}

.no-items-message {
    text-align: center !important;
    font-size: 1.2rem;
    color: #888;
    padding: 20px;
    margin-top: 5rem;
    min-height: 50vh;
}

.no-items-message p{
    text-align: center !important;
}

/*ANIMATION*/
@keyframes card-fade-in {
    to {
        transform: translateY(0px);
        scale: 1;
        opacity: 1;
    }
}

.card-fade-in {
    scale: .5;
    opacity: 0;
    
    animation: card-fade-in 1s ease-in forwards;
}

.card-animation-enter .card {
    opacity: 0;
}

.card-animation-enter.card-fade-in .card {
    opacity: 1 !important;
}

@keyframes card-hover {
    to{
        transform: translateY(-10px) scale(1.035)
    }
}

/*MEDIA SCREEN*/


@media only screen and (max-width: 1200px){
    .card-container{
        grid-template-columns: repeat(2, minmax(20vw, 50vw));
    }
}
@media only screen and (max-width: 850px){
    .card-container{
        grid-template-columns: repeat(1, minmax(20vw, 1fr));
    }
}

@media only screen and (max-width: 600px){
    .card-container{
    }
}