.description-bar-container {
    background-color: var(--immobile-description-bar-bg);
    padding: 1rem;
    border-radius: 15px;
    margin: 0 auto;
    max-width: 900px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: -50px;
    z-index: 200;
}

.description-container-grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

.description-item > *{
    color: var(--immobile-description-item-color);
    margin: 0 !important;
    text-align: center;
}

.immobile-section {
    width: 65vw;
    color: var(--immobile-color);
    position: relative;
    top: -50px;
}

.immobile-section-bg-container {
    width: 100vw;
    position: fixed;
    top: 0;
    min-height: 100vh;
    z-index: 0;
}

.immobile-section-bg {
    width: 65vw;
    background-color: var(--immobile-bg);
    box-shadow: var(--shadow-light-black);
    min-height: 100vh;
}

.immobile-section-carousel{
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 1rem;
}

.immobile-carousel-img {
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 60vh;
}

.immobile-carousel-img > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: var(--rounded-card);
    box-shadow: var(--shadow-md-black);
}

.immobile-carousel-imgs-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 1rem;
    height: 60vh;
    overflow: scroll;
}

.immobile-carousel-imgs-container > img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: var(--rounded-card);
    margin: auto;
    cursor: pointer;
    box-shadow: var(--shadow-md-black);
}

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    border: none;
    width: 5vw;
    height: calc(60vh - 3rem);
    margin: 0 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
    transition: background-color 0.3s ease;
}

.carousel-button:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.prev-button {
    left: 10px;
    border-radius: var(--rounded-card) 0 0 var(--rounded-card);
}

.next-button {
    right: 10px;
    border-radius:  0 var(--rounded-card) var(--rounded-card) 0;
}

.immobile-section-description, .immobile-section-caratteristiche{
    padding: 2rem;
}

.immobile-description-title, .immobile-caratteristiche-title{
    text-align: left;
}

.immobile-description-hr, .immobile-caratteristiche-hr {
    width: 50%;
    border: 2px solid var(--header-hr);
    margin: 0.5rem 0;
    text-align: left;
}

.immobile-description-p {
    text-align: justify;
}

.immobile-description-p .location-link {
    color: var(--immobile-description-location-color);
}

.immobile-caratteristiche-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.immobile-caratteristiche-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 2rem;
    margin: 0.5rem 0;
}

.immobile-caratteristiche-item-title {
    font-weight: bold;
}

.immobile-caratteristiche-item-value {
    font-size: 1.1rem;
}


/*MEDIA SCREEN*/
@media only screen and (max-width: 1200px){
    .immobile-section-carousel{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media only screen and (max-width: 850px){
    .immobile-section-carousel{
        grid-template-columns: repeat(1, 1fr);
        padding: 1rem 0;
    }
    .description-bar-container{
        max-width: 95vw !important;
    }
    .immobile-carousel-imgs-container{
        height: 40vh;
    }
}

@media only screen and (max-width: 600px){
    .description-bar-container{
        max-width: 95vw !important;
    }
    .immobile-section-carousel{
        grid-template-columns: repeat(1, 1fr);
        padding: 1rem 0;
    }
    .description-container-grid{
        grid-template-columns: repeat(2, 1fr);
    }
    .immobile-caratteristiche-container{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .immobile-section{
        width: 100vw;
    }
    .immobile-section-bg{
        width: 100vw;
    }
    .immobile-carousel-imgs-container{
        height: 40vh;
    }
}