.header {
    background-image: var(--header-bg-img);
    background-size: cover;
    background-position: center;
    height: 65vh;
    color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 0 0 25vw 25vw;
    z-index: 100;
}

.header-immobili{
    height: 30vh !important;
}

.header h1 {
    font-size: 5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.header hr {
    width: 75%;
    border: 0.25rem solid var(--header-hr);
    margin: 1rem auto;
}

.header h3 {
    font-size: 1.5rem;
    font-weight: normal;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--header-overlay);
    border-radius: 0 0 25vw 25vw;
    box-shadow: var(--shadow-black);
}

.header-content {
    position: relative;
    z-index: 2;
}

/*MEDIA SCREEN*/
@media only screen and (max-width: 1200px){
    .header, .overlay {
        border-radius: 0 0 15vw 15vw;
    }
}
@media only screen and (max-width: 850px){
    .header, .overlay{
        border-radius: 0 0 0.5rem 0.5rem;
    }
}

@media only screen and (max-width: 600px){
    .header, .overlay{
        border-radius: 0 0 0.5rem 0.5rem;
    }
}