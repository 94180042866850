/* Stile per il banner a schermo intero */
.overlay-banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8); /* sfondo scuro semi-trasparente */
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1005;
    font-family: 'Arial', sans-serif;
}
.overlay-banner h1 {
    font-size: 2.5rem;
    text-align: center;
    font-weight: bold;
    margin: 0;
}
.overlay-banner p {
    font-size: 1.2rem;
    text-align: center;
    opacity: 0.8;
}