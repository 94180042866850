.mailto-button {
    position: fixed;
    bottom: 35vh;
    right: 3vw;
    border-radius: 50%;
    background: var(--mailto-bg);
    z-index: 100;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mailto-button a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mailto-button i {
    font-size: 1.5rem;
    color: var(--mailto-color);
}

.mailto-button a:hover i {
    color: var(--mailto-color-hover);
}
