.card-img-top {
    object-fit: cover;
    height: 300px;
}

.card{
    border: none !important;
    box-shadow: var(--color-card-shadow) !important;
    border-radius: var(--rounded-card) !important;
}

.card-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(20vw, 32vw));
    gap: 25px;
    max-width: 75vw;
    margin: 8rem auto;
    min-height: 10vh;
    min-height: 10svh;
}

.overlay-header{
    position: relative;
    top: -150px;
}

.card-animation-enter{
    transform: translateY(200px);
}

.card-item {
    /*transform: translateY(200px);*/
    height: 45vh !important;
    /*opacity: 1;*/
}

.card-item:hover{
    /*transform: scale(1.02) !important;
    translate: 0px -15px;*/
    animation: card-hover 0.10s ease-in forwards;
}

.card-item--container-img {
    position: relative;
    height: 100% !important;
}

.card-body {
    position: absolute;
    bottom: 30px;
    background-color: var(--card-body-bg);
    color: var(--card-body-color);
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: var(--rounded-card);
}

.card-body h5 {
    margin: auto !important;
}

.card-tag-left {
    position: absolute;
    width: 30%;
    margin: 0 2rem;
}

.card-tag-right {
    position: absolute;
    width: 30%;
    margin: 0 2rem;
    right: 0px;
}

.card-price {
    top: 30px;
    background-color: var(--card-price-bg);
    color: var(--card-price-color);
    border-radius: var(--rounded-card);
}

.card-type {
    top: 80px;
    background-color: var(--card-type-bg);
    color: var(--card-type-color);
    border-radius: var(--rounded-card);
}

.card-surface {
    top: 80px;
    background-color: var(--card-price-bg);
    color: var(--card-price-color);
    border-radius: var(--rounded-card);
}

.card-room {
    top: 30px;
    background-color: var(--card-type-bg);
    color: var(--card-type-color);
    border-radius: var(--rounded-card);
}

.card-tag-left h5, .card-tag-right h5 {
    margin: 0 !important;
    padding: 0.5rem;
    text-align: center;
}

.card-item--container-img img{
    object-fit: cover;
    height: 100% !important;
    width: 100% !important;
    border-radius: var(--rounded-card) !important;
}

.card-item--overlay-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-card-overlay);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-radius: var(--rounded-card) !important;
  }

.card-item--btn {
    position: absolute;
    bottom: 5%;
    left: 30%;
    right: 30%;
    
    display: grid;
    place-items: center;
    aspect-ratio: var(--btn-ratio);
    font-size: 1.15rem;
    font-weight: 700;
    padding: 0.15em .15em;
    color: var(--color-card-btn-text) !important;
    background-color: var(--color-card-btn-bg) !important;
    border-color: var(--color-card-btn-border) !important;
    border: var(--btn-bordered) solid !important;
    border-radius: var(--btn-rounded) !important;

    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;
  }
  
.card-item--btn:hover {
    transform: translateY(-10px);
    color: var(--color-card-btn-hover-text) !important;
    background-color: var(--color-card-btn-hover-bg) !important;
    border-color: var(--color-card-btn-hover-border) !important;
}

.card-title{
    color: var(--color-card-title) !important;
}
.card-text{
    color: var(--color-card-text) !important;
}

  

/*ANIMATION*/
@keyframes card-fade-in {
    to {
        transform: translateY(0px);
        scale: 1;
        opacity: 1;
    }
}

.card-fade-in {
    scale: .5;
    opacity: 0;
    
    animation: card-fade-in 1s ease-in forwards;
}

.card-animation-enter .card {
    opacity: 0;
}

.card-animation-enter.card-fade-in .card {
    opacity: 1 !important;
}

@keyframes card-hover {
    to{
        transform: translateY(-10px) scale(1.035)
    }
}

/*MEDIA SCREEN*/
@media only screen and (max-width: 1200px){
    .card-container{
        grid-template-columns: repeat(2, minmax(20vw, 50vw));
    }
}
@media only screen and (max-width: 850px){
    .card-container{
        grid-template-columns: repeat(1, minmax(20vw, 1fr));
        max-width: 90vw;
    }
}

@media only screen and (max-width: 600px){
    .card-container{
        margin: 15rem auto;
        max-width: 90vw;
    }
    h5{
        font-size: 0.95rem !important;
    }
}