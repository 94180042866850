:root {
  --rounded-card: 0.5rem;
  --color-skeleton: #e0e0e0;
  --color-skeleton-dark: #d0d0d0;
  --color-card-shadow: rgba(0, 0, 0, 0.1);
}

.card-img-top {
  object-fit: cover;
  height: 300px;
}

.card {
  border: none !important;
  box-shadow: 15px 15px 30px var(--color-card-shadow) !important;
  border-radius: var(--rounded-card) !important;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(20vw, 32vw));
  gap: 25px;
  max-width: 75vw;
  margin: auto;
  min-height: 60vh;
  min-height: 60svh;
}

.card-item {
  height: 45vh !important;
}

.card-item--container-img {
  position: relative;
  height: 50% !important;
}

.card-item--container-img .skeleton-img,
.card-body .skeleton-title,
.card-body .skeleton-text,
.card-body .skeleton-btn {
  background-color: var(--color-skeleton);
  border-radius: 5px;
  animation: loading 1.5s infinite linear;
}

.skeleton-img {
  width: 100%;
  height: 100%;
}

.skeleton-title {
  width: 60%;
  height: 20px;
  margin-bottom: 10px;
}

.skeleton-text {
  width: 80%;
  height: 15px;
  margin-bottom: 10px;
}

.skeleton-btn {
  width: 50%;
  height: 35px;
}

@keyframes loading {
  0% {
    background-color: var(--color-skeleton);
  }
  50% {
    background-color: var(--color-skeleton-dark);
  }
  100% {
    background-color: var(--color-skeleton);
  }
}

.card-body {
  padding: 20px;
}

.card-animation-enter {
  transform: translateY(200px);
}

.card-item--btn {
  position: absolute;
  bottom: 5%;
  left: 30%;
  right: 30%;
  display: grid;
  place-items: center;
  aspect-ratio: var(--btn-ratio);
  font-size: 1.15rem;
  font-weight: 700;
  padding: 0.15em .15em;
  color: var(--color-card-btn-text) !important;
  background-color: var(--color-card-btn-bg) !important;
  border-color: var(--color-card-btn-border) !important;
  border: var(--btn-bordered) solid !important;
  border-radius: var(--btn-rounded) !important;
  transition: transform 0.3s ease;
}

.card-item--btn:hover {
  transform: translateY(-10px);
  color: var(--color-card-btn-hover-text) !important;
  background-color: var(--color-card-btn-hover-bg) !important;
  border-color: var(--color-card-btn-hover-border) !important;
}

/* Animation for Card Fade-In */
@keyframes card-fade-in {
  to {
    transform: translateY(0px);
    scale: 1;
    opacity: 1;
  }
}

.card-fade-in {
  scale: 0.5;
  opacity: 0;
  animation: card-fade-in 1s ease-in forwards;
}

.card-animation-enter .card {
  opacity: 0;
}

.card-animation-enter.card-fade-in .card {
  opacity: 1 !important;
}

@keyframes card-hover {
  to {
    transform: translateY(-10px) scale(1.035);
  }
}

@media only screen and (max-width: 1200px) {
  .card-container {
    grid-template-columns: repeat(2, minmax(20vw, 50vw));
  }
}

@media only screen and (max-width: 850px) {
  .card-container {
    grid-template-columns: repeat(1, minmax(20vw, 1fr));
  }
}

@media only screen and (max-width: 600px) {
  .card-container {
    grid-template-columns: repeat(1, minmax(20vw, 1fr));
  }
}
