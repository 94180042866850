:root{
  /*GENERAL*/
  --rounded-card: 0.5rem;

  /*COLOR*/
  --green-color: #8FBC8F;
  --green-overlay: #A3CFA3;
  --white-overlay: #F8F8F895; 
  --white-color: #F8F8F8; 
  --black-overlay: #1C1C1C30;
  --black-light-overlay: #1C1C1C10;
  --black-shadow: #1C1C1C80;
  --black-color: #1C1C1C;
  --yellow-color: #F4A261;
  --shadow-black: 0px 4px 8px var(--black-shadow);
  --shadow-md-black: 0px 0px 30px var(--black-shadow);
  --shadow-light-black: 0px 0px 90px var(--black-shadow);

  /*NAV*/
  --nav-background: var(--white-color);
  --nav-item-color: var(--green-color);
  --nav-item-color-hover: var(--white-color);
  --nav-item-background: ;
  --nav-item-background-hover: var(--green-color);

  /*HEADER*/
  --header-hr: var(--green-color);
  --header-overlay: var(--black-overlay);
  --header-bg-img: url('https://www.lacasapatella.it/sfondoHeader.jpg');

  /*FILTER*/
  --filter-bg: var(--green-overlay);
  --filter-label-bg: var(--green-color);
  --filter-label-color: var(--white-color);
  --filter-select-bg: var(--white-color);
  --filter-select-color: var(--black-color);
  --filter-range-bg: var(--white-color);
  --filter-range-color: var(--black-color);
  --filter-button-bg: var(--white-color);
  --filter-button-bg-hover: var(--yellow-color);
  --filter-button-color: var(--yellow-color);
  --filter-button-color-hover: var(--white-color);

  /*CARD*/
  --card-body-bg: var(--white-color);/*var(--green-color);*/
  --card-body-color: var(--green-color);/*var(--white-color);*/
  --card-price-bg: var(--green-color);
  --card-price-color: var(--white-color);
  --card-type-bg: var(--white-color);/*var(--yellow-color);*/
  --card-type-color: var(--green-color);/*var(--white-color);*/
  --color-card-shadow: 15px 15px 30px var(--black-shadow);

  /*FOOTER*/
  --footer-bg: var(--green-color);
  --footer-color: var(--white-color);
  --footer-hr: var(--yellow-color);
  --footer-item-color: var(--white-color);

  /*SERVIZI*/
  --servizi-bg: var(--black-light-overlay);
  --servizi-section-title: var(--green-color);
  --servizi-color: var(--black-color);

  /*IMMOBILE*/
  --immobile-description-bar-bg: var(--green-color);
  --immobile-description-item-color: var(--white-color);
  --immobile-description-location-color: var(--green-color);
  --immobile-bg: var(--black-light-overlay);
  --immobile-color: var(--black-color);

  /*MAILTO BUTTON*/
  --mailto-bg: radial-gradient(circle, var(--green-color) 30%, var(--white-color) 100%);;
  --mailto-color: var(--black-color);
  --mailto-color-hover: var(--white-color);
}

body {
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  background-color: var(--color-body-bg) !important;
  min-height: 100vh;
  min-height: 100svh;
}

a:focus, button:focus, input:focus, select:focus{
  outline: 2px solid #005fcc;
  outline-offset: 2px;
}

a:hover{
  text-decoration: none !important;
}

p{
  text-align: justify;
}

.satinato {
  filter: blur(4.5px);
}